@import "@/src/styles/common.scss";
.page-item {
    background: rgb(249, 249, 249);
    .you-may-like-container {
        margin-bottom: 0.1rem;
        background: #fff;
        padding: 0.4rem 0.2rem;
        margin-top: 0.1rem;
        .you-may-like {
            font-family: var(--font-montserrat-medium);
            padding-bottom: 0.4rem;
            font-size: 0.28rem;
        }
    }
    // .similar-frames-container {
    //     background: #fff;
    //     margin-top: 0.1rem;
    //     padding: 0.4rem 0.2rem;
    //     margin-bottom: 0.1rem;
    //     .similar-frames {
    //         font-family: var(--font-montserrat-medium);
    //         font-size: 0.28rem;
    //         background: #fff;
    //         padding-bottom: 0.4rem;
    //     }
    //     .like-product-list {
    //         display: flex;
    //         flex-wrap: wrap;
    //         border-top: 0.02rem solid $gray_4;
    //         .like-product-item {
    //             width: 50%;
    //             padding: 0.5rem 0.08rem;
    //             border-bottom: 0.02rem solid $gray_4;
    //             border-right: 0.02rem solid $gray_4;
    //             .item-icon {
    //                 justify-content: space-between;
    //                 padding: 0 0.1rem;
    //                 margin-top: 0.2rem;
    //                 .products_name {
    //                     font-size: 0.24rem;
    //                 }
    //                 .icon {
    //                     font-size: 0.32rem;
    //                     color: $main;
    //                     margin-left: 0.1rem;
    //                 }
    //             }
    //             .price {
    //                 font-size: 0.24rem;
    //             }
    //             &:nth-child(2n) {
    //                 border-right: none;
    //             }
    //         }
    //     }
    // }
}
