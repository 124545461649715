@import "@/src/styles/common.scss";
@mixin svgIcon($important: null) {
    width: 0.48rem $important;
    height: 0.48rem $important;
    line-height: 1 $important;
    color: inherit $important;
}
@keyframes zIndexZero {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
.app-bar-stack-wrapper {
    z-index: 10 !important;
    position: relative;
    // animation: zIndexZero 0.3s;

    // appbarlabel
    .tool-bar-container {
        min-height: unset;
        height: 100%;
        padding-left: 0.2rem;
        padding-right: 1.2rem;
        width: 50%;
        .fm-menu-icon {
            @include svgIcon(!important);
            width: 0.4rem !important;
        }
        .link {
            display: flex;
            -webkit-box-align: center;
            align-items: center;
            height: 0.44rem;
            margin-left: 0.2rem;
            width: auto;
            .fm-image {
                width: 100%;
                height: 100%;
            }
        }
    }
    .bar-right-icon {
        margin-right: 0.4rem;
        @include svgIcon();
    }
    .bar-right-icon,
    .bar-right-icon-last {
        display: flex;
        justify-content: center;
        color: $text;
    }

    .icon-font-size {
        @include svgIcon();
    }
}

.bar-menu-container {
    padding: 0 !important;
}

.app-bar-container.app-bar-container {
    color: $text;
}
