@import "@/src/styles/common.scss";
.view-list-wrapper {
    .list-container {
        padding: 0;
        margin: 0;
        height: 100%;
    }
    .list-item-container {
        padding: 0;
        margin: 0;
    }
}

.list-item {
    margin: 0;
    padding: 0;
    width: 100%;
    .render-footer {
        width: 100%;
        padding: 0.18rem 0.3rem 0.3rem;
        .green-color {
            color: #25b6c3;
            line-height: 0.8rem;
            pointer-events: none;
        }
        .loading {
            background-color: #f5f5f9;
            padding: 0.64rem 0;
            text-align: center;
            @include use-font-normal();
        }
        .set-no-margin {
            margin: 0 !important;
        }
    }
}
