@import "@/src/styles/common.scss";
.show-details-container {
    background-color: #f9f9f9;
    position: relative;
    .price-container {
        justify-content: space-between;
        margin-top: 0.1rem;
        background-color: #fff;
        .price-content {
            width: 100%;
        }
    }
    .page-item {
        padding: 0.4rem 0.2rem;
        background: #fff;
        margin-top: 0.1rem;
    }
}
