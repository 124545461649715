@import "@/src/styles/common.scss";
.product-show-more-container {
    position: relative;
    .more-show-container {
        -moz-transform-origin: 0 0;
        -webkit-transform-origin: 0 0;
        -o-transform-origin: 0 0;
        transform-origin: 0 0;
        overflow-y: hidden;
        overflow-x: hidden;
        transition: all 0.3s;
        padding: 0.4rem 0.2rem;
        background: #fff;
        padding-bottom: 0;
    }
    .show-more-btn {
        display: flex;
        padding: 0.4rem 0;
        justify-content: center;
        background: #fff;
        text-transform: uppercase;
        @include use-font-normal();
        &.show-more-hide::after {
            content: " ";
            position: absolute;
            top: -1rem;
            left: 0;
            width: 100%;
            height: 1rem;
            background: linear-gradient(
                to bottom,
                white,
                rgba(255, 255, 255, 0.8),
                rgba(255, 255, 255, 0.5),
                rgba(255, 255, 255, 0)
            );
            background: -ms-linear-gradient(
                to bottom,
                white,
                rgba(255, 255, 255, 0.8),
                rgba(255, 255, 255, 0.5),
                rgba(255, 255, 255, 0)
            );
            background: -moz-linear-gradient(
                to bottom,
                white,
                rgba(255, 255, 255, 0.8),
                rgba(255, 255, 255, 0.5),
                rgba(255, 255, 255, 0)
            );
            background: -webkit-linear-gradient(
                to bottom,
                white,
                rgba(255, 255, 255, 0.8),
                rgba(255, 255, 255, 0.5),
                rgba(255, 255, 255, 0)
            );
            z-index: 3;
        }
        &.show-more-hide {
            position: relative;
            line-height: 0.4rem;
            background: #fff;
            &.show-more-hide:after {
                content: " ";
                position: absolute;
                top: -1rem;
                left: 0;
                width: 100%;
                height: 1rem;
                background: linear-gradient(
                    to top,
                    white,
                    rgba(255, 255, 255, 0.8),
                    rgba(255, 255, 255, 0.5),
                    rgba(255, 255, 255, 0)
                );
                background: -ms-linear-gradient(
                    to top,
                    white,
                    rgba(255, 255, 255, 0.8),
                    rgba(255, 255, 255, 0.5),
                    rgba(255, 255, 255, 0)
                );
                background: -webkit-linear-gradient(
                    to top,
                    white,
                    rgba(255, 255, 255, 0.8),
                    rgba(255, 255, 255, 0.5),
                    rgba(255, 255, 255, 0)
                );
                background: -moz-linear-gradient(
                    to top,
                    white,
                    rgba(255, 255, 255, 0.8),
                    rgba(255, 255, 255, 0.5),
                    rgba(255, 255, 255, 0)
                );
                z-index: 3;
            }
        }
        .arrow-icon-class {
            @include use-icon-small(null, $text);
            margin-left: 0.1rem;
            padding: 0;
            line-height: 1.5;
        }
    }
}
