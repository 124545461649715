@import "@/src/styles/common.scss";
.hide_close_btn {
    display: none !important;
}
.paper-container {
    margin: 0.32rem 0.2rem;
    max-width: calc(100vw - 0.4rem);
    &.paper_wrap {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        margin: 0;
    }

    .dialog_title {
        position: relative;
        font-size: 0.36rem;
        margin: 0;
        color: $text;
        line-height: 1.5;
        padding: 0.6rem 0.4rem 0.4rem;
        &.center {
            text-align: center;
        }
        &.right {
            text-align: right;
        }
        &.crossable {
            position: absolute;
            right: 0;
            top: 0.1rem;
        }
        .header-right-close {
            position: absolute;
            right: 0.2rem;
            top: 50%;
            transform: translateY(-50%);
            z-index: 1;
            @include use-icon-normal();
            &::before {
                position: absolute;
                left: 0;
            }
        }
    }

    .content {
        color: #888;
        line-height: 1.2;
        font-size: 0.3rem;
        border: none;
        padding: 0;
        &.center {
            text-align: center;
        }
        &.right {
            text-align: right;
        }
    }
    .content ~ .dialog-actions {
        width: 100%;
        padding: 0;
        .render-bottom {
            width: 100%;
            margin: 0;
            padding: 8px;
            box-sizing: content-box;
            border: none;
            &:not(:first-of-type) {
                border-left: 0.02rem solid rgba(0, 0, 0, 0.12);
            }
        }
        &.center {
            justify-content: center;
        }
    }
}
