@import "@/src/styles/common.scss";
.product-price-container {
    display: flex;
    padding: 0.6rem 0.2rem 0.4rem 0.2rem;
    background: #fff;
    padding-bottom: 0;
    align-items: center;
    .onsale-show-discount {
        justify-content: flex-start;
        .price {
            margin-top: 0.1rem;
            display: flex;
            align-items: flex-end;
            .price-value {
                font-size: 0.48rem;
                line-height: 0.5rem;
                color: $text;
                font-family: var(--font-montserrat-medium);
            }
            s {
                font-size: 0.24rem;
                margin-left: 0.1rem;
                color: $text_3;
            }
        }
    }
    .product-price-now {
        font-size: 0.48rem;
        font-family: var(--font-montserrat-medium);
    }
}
.page-item {
    padding: 0.4rem 0.2rem;
    background: #fff;
    margin-top: 0.2rem;
    .product-detail-content {
        justify-content: space-between;
        .icon-rating {
            @include use-icon-small();
        }
        .comment-num {
            @include use-font-small($main);
            margin-left: 0.04rem;
        }
    }
}
