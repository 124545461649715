@import "@/src/styles/common.scss";
.cropper {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: #ffffff;
    overflow-x: hidden;
    overflow-y: auto;
    .cropper-head {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 7.5rem;
        height: 1rem;
        // box-shadow:
        //     0 2px 4px rgba(0, 0, 0, 0.12),
        //     0 0 6px rgba(0, 0, 0, 0.04);
        font-size: 0.32rem;
        font-family: var(--font-montserrat-medium);
        color: #333333;
        .cropper-cross {
            position: absolute;
            right: 9px;
            width: 32px;
            height: 32px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #fff;
            border-radius: 50%;
            cursor: pointer;
        }
    }
    .cropper-area {
        touch-action: none;
        position: relative;
        background: #f5f5f5;
        .cropper-img-box {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 auto;
            width: 6.04rem;
            height: 7.29rem;
            .img-content {
                * {
                    float: none;
                }
            }
            .mask {
                position: absolute;
                width: initial;
                height: initial;
            }
            .cropper-border {
                position: absolute;
                border: 0.02rem solid $main;
            }
        }
    }
    .button-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: #888;
        .flex {
            &.submit {
                margin-top: 0.4rem;
                width: 5rem;
                height: 0.72rem;
                background: $main;
                border-radius: 4px;
                font-size: 0.28rem;
                font-family: var(--font-montserrat);
                color: #fff;
                line-height: 0.32rem;
            }
            &.cancel {
                margin-top: 0.2rem;
                width: 5rem;
                height: 0.72rem;
                background: #fff;
                border: 0.02rem solid $main;
                border-radius: 4px;
                font-size: 0.28rem;
                font-family: var(--font-montserrat);
                color: $main;
                line-height: 0.32rem;
            }
        }

        .rotate {
            display: inline-flex;
            flex-direction: column;
            align-items: center;
            margin-top: 0.27rem;
            font-size: 0.28rem;
            .rotate-text {
                margin-top: 0.2rem;
            }
        }
    }
}
