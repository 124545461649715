@import "@/src/styles/common.scss";
.count-down-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: url("https://df5apg8r0m634.cloudfront.net/images/770b988f37409e579f108fd93c651058.png")
        no-repeat center center;
    background-size: 100%;
    padding: 0.15rem 0.2rem;
    .flash-sale {
        font-size: 0.26rem;
        color: #fff;
        line-height: 0.26rem;
        margin-bottom: 0.05rem;
        display: flex;
        align-items: center;
        .flash-sale-img {
            width: 0.16rem;
            height: 0.22rem;
            margin-right: 0.1rem;
        }
        .icon-faq-content {
            padding-left: 0.05rem;
        }
    }
    .count-down-font {
        font-size: 0.2rem;
        color: #fff;
        span {
            font-size: inherit;
        }
        .hs {
            background: #fff;
            color: $text;
            padding: 0.02rem 0.04rem;
            margin-left: 0.06rem;
            font-size: inherit;
        }
    }
    .modal-container {
        padding: 0 0.2rem;
        padding-top: 0.3rem;
        text-align: left;
        color: $text;
    }
    .day {
        padding-right: 0.5em;
    }
}

.dialog-paper .dialog-content {
    padding: 0 0.3rem 0.3rem;
    font-size: 0.28rem;
    line-height: 1.5;
    color: $text;
}
