@import "@/src/styles/common.scss";
.read-all-qas {
    width: auto !important;
    margin: 0 auto !important;
    color: #fff !important;
}
.answeritem-container {
    border-bottom: 0.02rem solid #c5c5c5;
    padding: 0.34rem 0;
    width: 100%;
    .answeritem-question-time-container {
        justify-content: flex-start;
        align-items: flex-start;
        .answeritem-question-time {
            font-size: 0.22rem !important;
            line-height: 0.52rem !important;
            color: #8e9399 !important;
            padding-bottom: 0.16rem !important;
        }
    }
    .answeritem-question-title {
        line-height: 1.5;

        font-family: var(--font-montserrat-medium);
    }
    .answeritem-question-detail {
        margin-top: 0.2rem;
        font-size: 0.26rem !important;
        line-height: 1.5 !important;
        color: $text_2 !important;
    }
    .answer-question-img {
        display: block;
        margin: 0.75rem 0 0.32rem 0;
        width: 2.2rem;
        height: auto;
    }
    .comment-container {
        margin-top: 0.2rem;
    }
    .answeritem-question-answer-num {
        margin-top: 0.1rem;
        font-size: 0.26rem;
        color: $main;
    }

    .comment-btn {
        color: $main;
        font-size: 0.28rem;
        margin-left: 0.4rem;
    }
}

// 这个是外层的
.modal-container {
    height: 100%;
    position: relative;
    text-align: left;
    color: $text;
    display: flex;
    flex-direction: column;
    padding-bottom: 1.1rem;
}
.bottom-line {
    padding-top: 1.2rem;
    .bottom-button-container {
        background-color: #fff;
        position: fixed;
        width: 100%;
        bottom: 0;
        left: 0;
        padding: 0.2rem 0.2rem;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 0.2rem 0.02rem;
    }
}

// 这个是评论的
.no-review-container {
    padding: 0.2rem 0;
    text-align: center !important;
}

.ask-to-open-writeform {
    align-items: center;
    text-align: center;
    display: flex !important;
    .icon--ask-to-open-writeform {
        display: inline !important;
        width: auto !important;
        margin-right: 0.08rem !important;
        @include use-icon-normal(!important);
    }
}

.header-show-list {
    width: 100%;
    text-align: center;
    font-size: 0.36rem;
    color: $text;
}

.drawer-children {
    padding: 0 0.2rem;
}
