@import "@/src/styles/common.scss";
.video-item-wrapper {
    padding: 0 0.2rem;
    position: relative;
    .video-container {
        width: 6rem;
        height: 168px;
        padding-bottom: 0;
        overflow: hidden;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        &::-webkit-scrollbar {
            width: 0;
        }
        video {
            width: 100%;
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        .video-icon {
            position: absolute;
            font-size: 0.9rem;
        }
    }
}

.video-guide-model-cover {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
    .model-close {
        position: absolute;
        right: 5px;
        top: 0;
        padding: 5px;
        z-index: 99;
    }

    .frame-container {
        position: fixed;
        position: releated;
        bottom: 0;
        // left: ${"0"}; TODO: 啥意思？？
        z-index: 999;
        width: 100%;
        &.frame-container-center {
            top: 0;
            margin: auto;
            display: flex;
            align-items: center;
            .model-close {
                top: 20vh;
            }
        }
    }
}

// 何去何从
.video-container-title {
    font-size: 14px;
    color: $text;
    font-family: var(--font-montserrat-medium);
}
.video-mask {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
.video-icon {
    font-size: 45px;
    color: #fff;
    cursor: pointer;
    opacity: 0.7;
}
.video-icon:hover {
    opacity: 1;
}

.close-button {
    position: absolute;
    top: -25px;
    right: -40px;
    font-size: 20px;
    color: white;
    cursor: pointer;
}
