@import "@/src/styles/common.scss";
.visibility-hidden {
    visibility: hidden;
}
@keyframes imageInOrOut {
    0% {
        opacity: 0;
        transform: scale3d(0.8, 0.8, 0.8);
    }
    100% {
        opacity: 1;
        transform: scale3d(1, 1, 1);
    }
}
.before-animation.product-img-load {
    animation: imageInOrOut ease 0.3s;
    visibility: visible;
}
.before-animation {
    visibility: hidden;
}
.image {
    height: auto;
    font-size: 0;
    display: block;
    &.defaultWidth {
        width: 100%;
    }
}
.img-content {
    max-width: 100%;
    height: auto;
}

.picture-label {
    font-size: 0;
}
