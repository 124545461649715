@import "@/src/styles/common.scss";
// .shop-car-title {
//     margin: 0;
//     height: 0.88rem;
//     text-align: center;
//     width: 100%;
//     font-size: 0.36rem;
//     line-height: 0.88rem;
//     font-family: inherit;
// }
.box-container {
    .wishlist-container {
        height: 100%;
        .wishlist-empty-container {
            .heart-tap-tips {
                margin-bottom: 0.4rem;
            }
            .sigin-in-tips {
                padding: 0.4rem 0;
                border-top: 0.02rem solid $gray_4;
                font-size: 0.28rem;
                color: $text;
                .wish-list-login {
                    color: $main;
                    width: fit-content;
                    height: fit-content;
                    border-radius: 0;
                    font-size: inherit;
                    span {
                        display: none;
                    }
                }
            }
        }
    }
    .children-container {
        height: 100%;
        max-height: calc(
            100vh - 1.6rem
        ); // header: pt: 0.6 + fs: 0.4 * 1.5 + pb: 0.4
        .viewlist-container {
            height: 100%;
        }
    }
}
