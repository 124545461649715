@import "@/src/styles/common.scss";
.share-compoent-dialog-container {
    .product-detail-share-title {
        font-size: 0.32rem;
    }
    .share-container {
        display: flex;
        justify-content: center;
        margin-bottom: 0;
    }
    .share-component-dialog-title {
        padding-bottom: 0;
    }
    .share-bottom {
        height: 1rem;
        border-top: 0.02rem solid #ddd;
    }
}
