@import "@/src/styles/common.scss";
.bottom-notice-container.bottom-notice-bg {
    font-size: 0.28rem;
    color: #fff;
    text-align: center;
    margin: 0;
    background-size: auto 100%;
    padding: 0.2rem;
    height: auto;
}
