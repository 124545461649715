@import "@/src/styles/common.scss";
.more-info-button {
    flex: 1;
}

.try-on-detail-img-container {
    display: flex;
    height: 2rem;
    justify-content: space-around;
    align-items: center;
    .try-on-detail-img {
        width: 3.5rem;
        display: flex;
        align-items: center;
        border: 0.02rem solid #efefef;
        .product-color-img {
            width: 100%;
            height: auto;
        }
    }
    .try-on-detail-text {
        .try-on-details-p-title {
            font-size: 0.32rem;
            font-family: var(--font-montserrat-medium);
        }
        p {
            margin-bottom: 0.1rem;
            font-size: 0.24rem;
        }
        .try-on-details-p {
            margin-bottom: 0.2rem;
        }
        .price {
        }
    }
}
.mention-tip {
    font-size: 0.28rem;
    margin-bottom: 0.4rem;
    border-top: 0.02rem solid #efefef;
    margin-top: 0.4rem;
    padding-top: 0.3rem;
}
.frame-details {
    font-size: 0.32rem;
    margin-bottom: 0.4rem;
    font-family: var(--font-montserrat-medium);
}
.dec-list-container {
    flex-wrap: wrap;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    .product-dec-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 33%;
        padding: 0.2rem 0;
        margin-bottom: 0.2rem;
        color: $text_3;
        text-align: center;
        height: 2.5rem;
        max-height: 4rem;
        font-size: 12px;
        p {
            margin: 0.2rem 0;
            color: $text;
        }
        b {
            color: $text;
        }
        .product-dec-item-image {
            min-height: 45px;
        }
    }
}
.select-lens-container {
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 0.2rem;
    background: #fff;
    box-shadow: 0px 00.06rem 0.12rem rgba(0, 0, 0, 0.08);
    width: 100%;
    .select-lens-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}
