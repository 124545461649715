@import "@/src/styles/common.scss";
.select-package-container {
    color: $text;
    opacity: 1;
    text-align: left;

    .select-package-content {
        .select-package-img {
            justify-content: flex-start;
            align-items: flex-start;
            margin-bottom: 0.4rem;
            .active-img {
                width: 3rem;
                margin-right: 0.3rem;
                border: 0.02rem solid $gray_4;
                flex-shrink: 0;
            }
            .price-container {
                justify-content: flex-start;
                align-items: flex-start;
                .price {
                    font-family: var(--font-montserrat-medium);
                    font-size: 0.48rem;
                    s {
                        font-size: 0.24rem;
                        margin-left: 0.1rem;
                        color: $text_3;
                        font-family: var(--font-montserrat);
                    }
                }
                .package-lens-text {
                    font-size: 0.24rem;
                    color: $text_3;
                }
            }
        }
    }
    hr {
        width: 100%;
        background: $gray_4;
        margin: 0.4rem 0;
    }
}
