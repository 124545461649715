@import "@/src/styles/common.scss";
.delivey-container {
    width: 100%;
    color: $text;
    text-align: left;
    .flex-line {
        justify-content: flex-start;
        display: flex;
    }
    .stepper {
        .step {
            margin: 0;
            .label-container {
                display: flex;
                flex-direction: row;
                font-size: 0.28rem;
                margin: 0;
                width: auto;
                align-items: center;
                .label {
                    font-size: inherit;
                    margin: 0 0 0 0.1rem;
                }
                .step-icon {
                    width: auto;
                    @include use-icon-normal(null, $main);
                }
                .icon-container {
                    width: auto;
                }
            }

            .content {
                display: flex;
                .bar {
                    width: 0.02rem;
                    background: $main;
                    margin: 0.1rem 0.25rem;
                }
                .process {
                    margin: 0 0.1rem;
                    font-size: 0.24rem;
                    color: $text_3;
                    padding: 0.5rem 0;
                    p {
                        margin-top: 0.2rem;
                        display: flex;
                        span {
                            width: auto;
                        }
                        justify-content: flex-start;
                        .popover-button {
                            width: auto;
                            margin: 0;
                            color: $main;
                        }
                    }
                    * {
                        font-size: inherit;
                    }
                }
            }
            // *,
            .vertical,
            .content {
                width: auto;
                margin: 0;
            }
        }
    }
}
.summary-content {
    font-family: var(--font-montserrat-medium);
    font-size: inherit;
    margin: 0 !important;
    .expanded {
        margin: 0 !important;
    }
}
.expanded {
    margin: 0;
    &.expanded-icon-wrapper {
        transform: rotate(90deg) !important;
    }
}
