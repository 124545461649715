@import "@/src/styles/common.scss";
.rx-confirm-dialog-container {
    padding-bottom: 0.8rem;
    .m-rx-confirm {
        margin: 0 auto;
        p {
            font-size: 0.28rem;
            color: $text;
            margin: 0;
        }
        .rx-confirm-container {
            border: 0.02rem solid #d9d9d9;
            margin: 0.4rem auto;
            .rx-line {
                padding: 0 0.3rem;
                height: 0.8rem;
                display: flex;
                align-items: center;
            }

            .rx-vlaues {
                border-top: 0.02rem solid #d9d9d9;
                border-bottom: 0.02rem solid #d9d9d9;
                padding: 0.2rem 0.14rem;
                .line {
                    display: flex;
                    justify-content: space-between;
                    p {
                        text-align: left;
                        height: 0.8rem;
                        span:first-child {
                            margin-right: 5px;
                        }
                    }
                }
            }
        }
    }
}
.ufmeuirxconfirm {
    .footer-container {
        padding: 0.4rem 0.4rem 0;
    }
}
