@import "@/src/styles/common.scss";
.review-comment-container {
    display: flex;
    margin-bottom: 0.4rem;
    font-size: 0.28rem;
    .review-comment-who {
        padding: 0 0.24rem;
        background: #eee;
        border: 0.02rem solid $gray_4;
        border-radius: 0.08rem;
        border-right: 0;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        height: auto;
        display: flex;
        align-items: center;

        .review-comment-heard {
            max-width: 0.8rem;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            display: block;
        }
    }
    .review-comment-input {
        padding: 0.04rem 0.2rem;
        width: 100%;
        border: 0.02rem solid $gray_4;
        border-left: 0;
        border-radius: 0;
    }
}
