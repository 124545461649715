// 主色
$main: #2ea7e0; // 常用经典色调
// 辅色
// 字体颜色
$text: #121a24;
$text_50_percent: rgba(18, 26, 36, 0.5);
// 灰色
$gray_4: #e9eaec;
// 语义色
$maingray: #333;
$white: #fff;
$disabled: #dfdfdf; // disabled-color
$buttonHover: #229ae5; // hover-color
$buttonDarkHover: #666; // 比主色灰略浅一点儿
$hoverTextColor: rgba(255, 255, 255, 0.6);
$textTransparentColor: transparent; // button-text-bg
$warningColor: #e54542;
$warningHoverColor: #cd403e;
$badgeColor: #e15e8a;
$placeholderColor: #8e9399;
:export {
    main: $main;
    text: $text;
    gray_4: $gray_4;
    badgeColor: $badgeColor;
    text_50_percent: $text_50_percent;
    placeholderColor: $placeholderColor;
}

@mixin ellipsis() {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
