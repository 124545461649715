@import "@/src/styles/common.scss";
.firmoo-reset-password.i-have-any-means-but-let-btn-css-win {
    text-align: left;
    padding-top: 0.4rem;
    .firmoo-reset-password-title {
        margin-bottom: 0.8rem;
        font-family: var(--font-montserrat-bold);
        display: flex;
        align-items: center;
        .firmoo-reset-password-suc {
            width: 0.44rem;
            height: 0.44rem;
            background: rgba(33, 172, 78, 1);
            border-radius: 50%;
            text-align: center;
            line-height: 0.44rem;
            margin-right: 0.2rem;
            .icon-checked-style {
                font-family: var(--font-montserrat-bold);
                color: #fff;
            }
        }
    }
    p {
        text-align: justify;
        line-height: 1.2;
    }
    p + p {
        margin-top: 0.4rem;
    }
    .ok-btn.success-send-email-ensure {
        border-radius: 0.08rem;
        margin: 0.4rem auto;
        width: 2rem;
        height: 0.96rem;
        line-height: 1.2rem;
        font-size: 0.32rem;
    }
}
