@import "@/src/styles/common.scss";
.Black {
    background: #000 none repeat scroll 0 0;
    color: #fff;
}

.White {
    background: #fff none repeat scroll 0 0;
    border: 0.02rem solid #ccc;
    box-sizing: border-box;
}

.Clear {
    background: #dcdcdc none repeat scroll 0 0;
}

.Antique {
    background: #4a3c29 none repeat scroll 0 0;
}

.Blue {
    background: #00f none repeat scroll 0 0;
}

.Brown {
    background: #a52a2a none repeat scroll 0 0;
}

.Burgundy {
    background: #5c0323 none repeat scroll 0 0;
}

.Gold {
    background: #9a7a3f none repeat scroll 0 0;
}

.Grain {
    background: #f5deb3 none repeat scroll 0 0;
}

.Green {
    background: #008000 none repeat scroll 0 0;
}

.Grey {
    background: #808080 none repeat scroll 0 0;
}

.Gunmetal {
    background: #655742 none repeat scroll 0 0;
}

.Matt {
    background: #313131 none repeat scroll 0 0;
}

.Multicolor {
    background: rgba(0, 0, 0, 0)
        url("/__firmoo/static/images/icon/Multicolored.jpg?inline") no-repeat scroll;
    background-size: 100% 100%;
}

.Orange {
    background: #ffa500 none repeat scroll 0 0;
}

.Pattern {
    background: rgba(0, 0, 0, 0) url("/__firmoo/static/images/icon/Pattern.jpg?inline")
        no-repeat scroll;
    background-size: 100% 100%;
}

.Pink {
    background: #ffc0cb none repeat scroll 0 0;
}

.Purple {
    background: #800080 none repeat scroll 0 0;
}

.Red {
    background: #f00 none repeat scroll 0 0;
}

.Silver {
    background: #c0c0c0 none repeat scroll 0 0;
}

.Tortoise {
    background: rgba(0, 0, 0, 0) url("/__firmoo/static/images/icon/Tortoise.png?inline")
        no-repeat scroll;
    background-size: 100% 100%;
}

.Yellow {
    background: #ff0 none repeat scroll 0 0;
}

.Chocolate {
    background: #d2691e none repeat scroll 0 0;
}

.Ivory {
    background: #f6f6cb none repeat scroll 0 0;
}

.color-item {
    display: inline-block;
    width: calc(0.36rem * var(--scale));
    height: calc(0.36rem * var(--scale));
    border-radius: 50%;
    background-size: contain;
    box-shadow: 0 0 0.05rem #ddd;
    margin: 0;
}

.product-select-item-content {
    padding: calc(0.06rem * var(--scale));
    line-height: 0;
    border-radius: 100%;
    border: 0.02rem solid transparent; // 撑开高度
    margin-right: 0.1rem;
}
.pruduct-select-item-active {
    border: 0.02rem solid $main;
}
