@import "@/src/styles/common.scss";
.bubble {
    position: fixed;
    bottom: 10%;
    left: 0;
    overflow: hidden;
    z-index: 12;
    max-width: 75px;
    max-height: 75px;
    .bubble-radius {
        border-radius: 50%;
        height: 65px;
        width: 65px;
        z-index: 9;
        padding: 5px;
    }
    .close-btn {
        position: absolute;
        top: -8px;
        right: 0px;
        font-size: 0.4rem;
        cursor: pointer;
    }
}
