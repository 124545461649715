@import "@/src/styles/common.scss";
.error-main {
    max-width: 80%;
    margin: 0 auto;
    height: calc(72vh);
    font-weight: 600;
    .error-form {
        display: "table-cell";
        vertical-align: "middle";
        text-align: "center";
    }
}

.text-center {
    text-align: center;
}

.error-statusCode {
    margin: 10px auto;
    font-size: 108px;
    color: $main;
    line-height: 1;
}

.href_a {
    color: $main;
    text-decoration: underline;
}

.tip {
    font-size: 24px;
    color: #a7a8a8;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 24 * 1.3 * 3px;
}
.e_img {
    margin: 30px 0;
}
.b-text {
    font-size: 20px;
    color: #a7a8a8;
}
.more-info {
    margin: 10px 0;
    color: #a7a8a8;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 0.28 * 5rem;
}

@media screen and (max-width: 800px) {
    /*当屏幕尺寸小于600px时，应用下面的CSS样式*/
    .error-statusCode {
        font-size: 80px;
    }
    .tip {
        font-size: 20px;
        max-height: 20 * 1.3 * 3px;
    }
    .b-text {
        font-size: 18px;
    }
    .more-info {
        font-size: 14px;
        height: 14 * 5px;
    }
}
