@import "@/src/styles/common.scss";
.basket-offline-title {
    padding-left: 0.2rem;
    padding-top: 0.6rem;
    margin-bottom: 0.36rem;
    display: flex;
    align-items: flex-end;
    span {
        @include use-font-title();
        font-size: 0.36rem;
    }
    .clear-btn {
        margin: 0 0.2rem !important;
    }
}

.empty-container {
    // empty
    .basket-offline-title-of-empty {
        margin-bottom: 0.54rem;
        padding-top: 0.36rem;
        @include use-font-title();
        font-size: 0.36rem;
        .out-of-tip {
            @include use-font-small();
            margin-top: 0.16rem;
            color: #ff7826;
            cursor: pointer;
        }
    }
    .basket-empty-container {
        .title {
            @include use-font-normal();
            text-align: center;
            font-size: 0.36rem;
            margin-bottom: 0.2rem;
        }
        .text {
            @include use-font-small();
            text-align: center;
            max-width: 5rem;
            margin: 0 auto;
        }
        .login-btn {
            color: $main;
            cursor: pointer;
        }
    }
}

.basket-wrapper {
    padding: 0.6rem 0.2rem 0.36rem;
    & + :not(.basket-products-list-no-share) {
        padding-bottom: 2rem;
    }
}
.basket-list-title {
    @include use-font-title();
    font-size: 0.36rem;
}

.basket-img {
    min-height: 4.3rem;
    width: 3.48rem;
    margin-top: 0.4rem;
    .ad-title {
        margin-top: 0.2rem;
        text-align: center;
        @include use-font-small();
    }
}

.basket-products-list-no-share {
    // 产品列表
    .basket-list-content {
        padding: 0.4rem 0.2rem !important;
        background: #fff;
        flex-direction: column;
        align-items: flex-start !important;
        width: 100%;
    }

    .basket-list-content + .basket-list-content {
        margin-top: 0.2rem;
    }
}

.remove-basket-items {
    padding-bottom: 0.6rem !important;
    text-align: center;
}
.footer-ensure-container {
    .button-ensure {
        margin-top: 0.3rem !important;
    }
}

.radio-container {
    padding: 0.4rem 0.2rem 0.2rem;
    .selected-all {
        @include use-font-normal();
    }
}
