@import "@/src/styles/common.scss";
.try-on-color-list,
.try-on-color-lens-list {
    padding: 0 0.2rem;
    padding-top: 0.5rem;
    padding-bottom: 0.2rem;
    width: 100%;
    overflow: scroll;
    display: flex;
    justify-content: center;
    align-items: center;
}

.try-on-color-name {
    color: $text;
    display: flex;
    justify-content: center;
    align-items: center;
    .lens-tint-icon {
        @include use-icon-normal();
    }
}

.try-on-color-name::-webkit-scrollbar {
    display: none; /* Chrome Safari */
}

.tryon-color-select-item {
    padding: 0.08rem;
    border-radius: 50%;
    margin-right: 0.2rem;
    border: 0.02rem solid rgba(255, 255, 255, 0);
}
.tryon-color-select-item .inner,
.glass-color-content {
    width: 0.44rem;
    height: 0.44rem;
    border-radius: 50%;
}
.tryon-color-select-item .inner {
    box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.1);
}
.tryon-color-select-item-active {
    border: 0.02rem solid $main;
}
.tryon-color-select-item-boder {
    border: 0.02rem solid #dfdfdf;
}

@keyframes loading {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
.loading {
    animation: loading linear 1s infinite;
    width: 20px;
    height: 20px;
}
