@import "@/src/styles/common.scss";
.basket-container {
    background: rgb(244, 248, 250);
}

.checkbox-form-control-container {
    width: 100%;
    &:not(.no-share-container) {
        background-color: #fff;
    }

    .checkbox-product-item {
        flex-direction: column;
        align-items: flex-start !important;
    }
    .checkbox-item-root,
    .width-100persent {
        width: 100%;
    }
    .bordered-color-change {
        border-color: $gray_4 !important;
    }
    .basket-detail-hr {
        margin: 0.3rem 0;
        border: 0.01rem solid $gray_4;
    }
}

.basket-remove-btn {
    margin-left: 0.1rem;
    padding-left: 0.2rem;
    @include use-font-normal();
    color: $main;
    cursor: pointer;
}
