@import "@/src/styles/common.scss";
.m-footer-list {
    padding: 0;
    background-color: #fff;
    .my-accordion.bottom-nav-accordion-container {
        box-shadow: none;
        margin: 0;
        &::before {
            background: none; // 覆盖accordion对应div的before设置底线的问题
        }
        &.expanded-container {
            .accordion-details-container {
                // margin: 8px 0 0 16px;
            }
        }

        .title-root {
        }
        .accordion-details-container {
            .payment-image {
                margin: 0 auto;
                display: block;
            }
            .accordion-image,
            .payment-image {
                height: 40px;
                width: auto;
            }
        }
    }
}

.m-footer-share {
    justify-content: center;
    flex-wrap: wrap;
    background: #fff;
    padding: 15px 0.3rem 1rem;
    .copyright {
        text-align: center;
        color: $text_3;
        line-height: 32px;
        margin: 0;
    }
}
