@import "@/src/styles/common.scss";
@import "../common.module.scss";
.form-control {
    .form-group {
        .form-control-label {
            padding: 0;
            margin: 0;
            &.square {
                padding: 0;
                margin: 0;
                .radio-label-item {
                    padding: 0.08rem;
                    & > span svg {
                        font-size: 0.4rem;
                    }
                }
                .form-label {
                    @include use-font-normal(#8e9399);
                    letter-spacing: normal;
                    margin-left: 0.3rem;
                }
                .radio-root {
                    border-radius: 0.08rem;
                    border: 0.02rem solid #8e9399;
                    width: 0.48rem;
                    height: 0.48rem;
                    &.radio-checked {
                        background-color: $main;
                        border-color: $main;
                        color: #fff;
                        & ~ .form-label {
                            color: $text; // checked的字体颜色为正常色
                        }
                    }
                }
                .checked-icon-font {
                    font-size: 0.28rem;
                }
            }
            &.circle {
                .form-label {
                    @include use-font-normal();
                    letter-spacing: normal;
                    margin-left: 0.2rem;
                }
                .radio-root {
                    border-radius: 50%;
                    border: 0.04rem solid #c5c9cd;
                    width: 0.4rem;
                    height: 0.4rem;
                    &.radio-checked {
                        border-color: $main;
                        color: #fff;
                        & ~ .form-label {
                            color: $main; // checked的字体颜色为正常色
                        }
                    }
                }
                .checked-circle {
                    width: 0.16rem;
                    height: 0.16rem;
                    position: absolute;
                    background: $main;
                    border-radius: 50%;
                }
            }
        }

        .form-control-label + .form-control-label {
            margin-top: 0.3rem;
        }
    }
    .note {
        font-size: 0.26rem;
        padding: 0.14rem;
        margin: 0;
    }
}

// 以下是不要的
.radio-container {
    display: flex;
    align-items: flex-start;
    align-items: center;
}

.radio-box {
    width: 0.44rem;
    height: 0.44rem;
    background: #fff;
    border: 0.02rem solid #999;
    margin-right: 0.21rem;
    border-radius: 0.08rem;
}

.radio-container p {
    @include use-font-normal();
}

.radio-img {
    height: auto;
    margin-right: 0.3rem;
}

.radio-box-checked {
    width: 0.44rem;
    height: 0.44rem;
    background: $main;
    border: 0.02rem solid $main;
    margin-right: 0.21rem;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.radio-box-checked-2 {
    width: 0.44rem;
    height: 0.44rem;
    background: #fff;
    border: 0.02rem solid #cccccc;
    margin-right: 0.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.check-img {
    width: 0.24rem;
    height: 0.24rem;
}

.radio-box-disable {
    background: #dfdfdf;
    opacity: 0.5;
}

.radio-title {
    flex: auto;
    text-align: left;
}
