@import "@/src/styles/common.scss";
.size-chart-page {
    line-height: 1;
    .size-guide {
        @include use-icon-small(null, $main);
    }
    .size-title {
        @include use-font-title();
        margin-bottom: 0.2rem;
    }
    .size-guide-container {
        justify-content: flex-start;
        .product-width-icon {
            width: 0.48rem;
            height: 0.48rem;
            background: #333333;
            color: #fff;
            font-size: 0.24rem;
            text-align: center;
            line-height: 0.48rem;
            margin-right: 0.2rem;
        }
    }
}
// 弹窗在body层面
.size-chart-container {
    .size-show-header {
        .header-content {
            color: $text;
            font-family: var(--font-montserrat-medium);
            font-size: 0.36rem;
            width: 100%;
            text-align: center;
        }
    }
    .size-chart-cotent {
        .tabs-wrapper {
            margin: 0;
            .tabs-container {
                border: none;
                margin-bottom: 0.56rem;
                height: 1.04rem;
                margin-top: -0.34rem;
                .tab-root {
                    font-size: 0.32rem;
                    padding-top: 0.34rem;
                    &:first-of-type {
                        margin-left: 0;
                    }
                }
            }
            .tabs-panel {
                padding: 0;
                margin: 0;
            }
        }
    }
}
