@import "@/src/styles/common.scss";
.outline-input-by-classname.root-select {
    font-size: 0.28rem;
    line-height: 1.2;
    height: 0.88rem;
    width: 100%;
    .icon-config-class {
        font-size: 0.44rem;
        width: 100%;
        box-sizing: border-box;
        top: 0;
        right: 0;
        padding-left: 70%;
        height: 100%;
        padding-right: 0.1rem;
    }
    .notchedOutline,
    & > fieldset {
        border: none;
    }
}
.menu-item {
    display: flex;
    justify-content: center;
    &.active-item {
        border: 0.02rem solid #ddd;
        border-left: none;
        border-right: none;
        color: #000 !important;
        font-size: 0.3rem !important;
    }
}
