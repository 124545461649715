@import "@/src/styles/common.scss";
.user-image-show-container {
    .user-image-show-link {
        margin-bottom: 10px;
        justify-content: flex-end;
        .view-more-link {
            padding: 10px;
            text-transform: uppercase;
        }
    }
    .loading-content {
        height: 5.8rem;
    }
    .user-iamge-show-carousel-container {
        // 实现用户秀三种布局
        .render-image-container {
            width: 100%;
            display: grid;
            min-height: 5.8rem;
            height: max-content;
            grid-template-columns: repeat(4, 4fr); // 四列
            grid-template-rows: repeat(3, 4fr); // 三行
            gap: 0.2rem; // 单个item间的间隙0.2rem
            .image-content {
                .user-cms-show-img {
                    width: 100%;
                    height: 100%;
                    overflow: hidden;
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
            }
            &.render-image-container-1 {
                .image-content {
                    &:nth-of-type(1) {
                        // 第一个特殊处理，横向占据2列，即1-3；纵向占据同横向
                        grid-row: 1 / 3;
                        grid-column: 1 / 3;
                        height: calc(1.8rem * 2 + 0.2rem);
                    }
                    &:not(&:nth-of-type(1)) {
                        height: 1.8rem;
                    }
                }
            }
            &.render-image-container-2 {
                grid-template-areas:
                    "a a b c"
                    "a a f f"
                    "d e f f";
                .image-content {
                    height: auto;
                    &:not(&:first-of-type, &:last-of-type) {
                        height: 1.8rem;
                    }
                    &:first-of-type {
                        grid-area: a;
                    }
                    &:last-of-type {
                        grid-area: f;
                    }
                }
            }
            &.render-image-container-3 {
                // grid-template: repeat(2, 2fr) / repeat(2, 2fr);
                grid-template-rows: repeat(2, 2fr);
                grid-template-columns: repeat(2, 2fr);
                .image-content {
                    height: calc(5.8rem / 2);
                    // grid-row: 1;
                    // grid-area: auto;
                }
            }
        }
        .user-image-show-carousel-dots {
            position: relative;
            margin-top: 0.1rem;
        }
    }
}

// video
.preview-video {
    &,
    .preview-video-content {
        position: relative;
    }

    video {
        width: 100%;
    }
    .video-mask {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        .video-icon {
            font-size: 45px;
            color: #fff;
            cursor: pointer;
            opacity: 0.7;
        }
        .video-icon:hover {
            opacity: 1;
        }
    }
}

// custom-show
.custom-show-container-pay-attention-not-customer {
    margin-top: 0.2rem;
    padding: 0.4rem 0.2rem;
    background-color: #fff;
    .h5-show-title {
        font-size: 0.32rem;
        font-family: var(--font-montserrat-bold);
        margin-bottom: 0.4rem;
        text-align: center;
    }
    .h5-show-text {
        font-size: 0.28rem;
        line-height: 1.2;
        text-align: center;
        margin-bottom: 0.4rem;
    }
    .h5-show-text p {
        line-height: 1.2 !important;
    }
    .tabs-container {
        .tabs-bar-container {
            border-bottom: 0.02rem solid #efefef;
        }
        .tab-item {
            font-size: 0.28rem;
        }
    }
    .render-videos-container {
        .render-videos-link-container {
            margin-bottom: 10px;
            justify-content: flex-end;
            .render-videos-link {
                padding: 10px;
                text-transform: uppercase;
            }
        }
        .videos-item {
            margin-bottom: 0.4rem;
            .videos-iframe {
                max-width: 100%;
                width: 100%;
                height: 4.2rem;
                border: none;
            }
            .item-title {
                margin-top: 0.2rem;
                &.default-layout-extra-config {
                    color: $text_3;
                    justify-content: space-between;
                }
                .author {
                }
            }
        }
    }
}

// video-guide
.video-guide-container {
    margin-top: 0.2rem;
    padding: 0.4rem 0.2rem;
    background-color: #fff;
    .h5-show-title {
        font-size: 0.32rem;
        margin-bottom: 0.2rem;
        text-align: center;
        font-family: var(--font-montserrat-bold);
    }
    .h5-show-text {
        padding: 0px 0.2rem;
        text-align: center;
        margin-bottom: 0.2rem;
    }
    .video-link {
        margin-bottom: 0.3rem;
        text-align: center;
        .video-link-content {
            width: max-content;
            margin: 0 auto;
        }
    }
    .video-container {
        margin-top: 0.2rem;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: flex-start;
        display: flex;
        .video-item {
            width: calc(50% - 0.1rem);
            margin-bottom: 0.3rem;
        }
    }
}

// .video-item
.video-guides-box {
    text-align: center;
    .video-guides-item {
        position: relative;
        background-color: #ccc;
        overflow: hidden;
        .video-image {
            cursor: pointer;
        }
        .video-icon {
            position: absolute;
            top: 50%;
            left: 50%;
            font-size: 45px;
            color: white;
            opacity: 0.7;
            cursor: pointer;
            transform: translate(-50%, -50%);
        }
        .video-icon:hover {
            opacity: 1;
        }
    }
    .video-title {
        margin-top: 0.2rem;
        font-size: 0.26rem;
    }
    .sub-link {
        margin-top: 0.1rem;
        font-size: 0.26rem;
        width: max-content;
        margin: 0 auto;
        margin-top: 0.05rem;
    }
}

// h5-ccontact
.home-need-help {
    background: #fff;
    padding: 0.4rem 0.2rem;
    margin-top: 0.2rem;
    .title {
        font-size: 0.32rem;
        color: rgba(29, 32, 35, 1);
        text-align: center;
        margin-top: 0;
        font-family: var(--font-montserrat-bold);
    }
    .footer-item {
        margin-left: 0.2rem;
        color: $text;
        line-height: 0.7rem;
        font-size: 0.28rem;
    }
    .icon-flex-start {
        justify-content: flex-start;
    }
}
