@import "@/src/styles/common.scss";
.rx-form-container.rx_form {
    border-top: 0.02rem solid $gray_4;
    .disabled {
        opacity: 0.4;
    }
    .lensType {
        padding: 0 0.15rem 0.41rem;
        display: flex;
        white-space: nowrap;
    }
}
.rx-form-content {
    padding: 0;
    &.hidden-img-rx {
        padding: 0;
    }
    &:not(.hidden-img-rx) {
        .hide-img-rx {
            justify-content: flex-end;
            padding-bottom: 0.2rem;
        }
    }
    .rx_form {
        &.hide-img-rx-form {
            border: none;
            margin-top: 0;
        }
        .rxBorder {
            position: relative;
            .m-form-error {
                margin-top: 0.1rem;
            }
            .values-0-container {
                position: relative;
                .lens-right-eye-od {
                    justify-content: space-between;
                }
                .rxTableLine {
                    width: 2rem;
                    .rxTableTop {
                        font-size: 0.28rem;
                        font-family: var(--font-montserrat-medium);
                        padding-bottom: 0.4rem;
                        color: $text;
                    }
                }
            }
            .values-1-container {
                justify-content: flex-start;
                padding: 0.4rem 0;
                .upload-type {
                    margin-left: "0.2rem";
                }
            }
        }
        .prescription-name {
            display: flex;
            align-items: center;
            .prescription-title {
                flex-shrink: 0;
            }
            .prescription-input {
                width: auto;
                margin-left: 0.2rem;
            }
        }
        .term-box {
            display: flex;
            align-items: flex-start;
            margin-top: 0.4rem;
            .right-box {
                margin-left: 0.1rem;
                font-size: 0.24rem;
                text-align: justify;
                & > div:not(:last-child) {
                    margin-bottom: 0.2rem;
                }
            }
        }
    }
    .logged-container {
        justify-content: flex-start;
        padding-bottom: 0.6rem;
        .input-container {
            width: 3rem;
        }
    }
    .unlogin-to-use-prescription {
        margin-bottom: 0.6rem;
        .lens-use-your-saved {
            margin-left: 0.2rem;
        }
    }
}

.justify-space-between {
    justify-content: space-between;
}

// drawer
.cant-use-prescription {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100vw;
    text-align: center;
    .dialog-content {
        padding: 0.6rem 0.4rem 0.4rem;
        text-align: center;
        color: $text;
    }
    .am-modal-content {
        border-radius: 0;
        padding-top: 0;
        .am-modal-body {
            padding: 0 0;
        }
    }
}
.footer-container {
    padding: 0.4rem 0.4rem 0 !important;
}

.error {
    color: #f8ac59;
}
