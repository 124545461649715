@import "@/src/styles/common.scss";
.carouselParent {
    padding: 0;
    width: 100%;
    .product-details-size {
        @include use-font-normal();
    }
    .view-size-container {
        justify-content: space-between;
        margin-bottom: 0.2rem;
        &.show-bottom {
            margin-bottom: 0.4rem;
        }
    }
    .view-size {
        margin-bottom: 0.4rem;
        @include use-font-title();
        &.hidden-bottom {
            margin-bottom: 0;
        }
    }
    .size-cover-container {
        margin-bottom: 0.2rem;
    }
}
