@import "@/src/styles/common.scss";
.user-nav-container {
    width: 100%;
    height: fit-content;
    background: #fff;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 0.5rem 0 0.6rem;
    .user-login-button-container {
        padding: 0 0.3rem;
        width: 100%;
        .sign-btn + .sign-btn {
            margin-top: 0.2rem;
        }
    }
    .prompt {
        font-size: 0.22rem;
        font-family: var(--font-montserrat-medium);
        color: rgba(192, 192, 192, 1);
        line-height: 1.2;
        margin: 0.4rem 0;
    }
    .fb-sign {
        display: flex;
        align-items: center;
        position: relative;
        img {
            width: 0.48rem;
            height: 0.48rem;
            margin-right: 0.2rem;
        }
        .face-book {
            font-size: 0.28rem;
            font-family: var(--font-montserrat-bold);
            color: rgba(64, 86, 149, 1);
            line-height: 0.23rem;
            margin: 0;
        }
        .facebook-hidden {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            margin: auto;
            opacity: 0;
        }
    }
    .bar {
        width: 100%;
        height: 0.02rem;
        background: #efefef;
        &.no-sign-bar {
            margin-top: 0.66rem;
            margin-bottom: 0.55rem;
        }
    }
    .user-nav-list-un {
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        .click-login-drawer-btn {
            color: #606972;
            padding: 0.25rem 0;
            font-size: 0.28rem;
            font-family: var(--font-montserrat);
        }
    }

    // logined
    .user-nav-sign-container {
        padding: 0 0.6rem;
        width: 100%;
        .title {
            font-size: 0.36rem;
            font-family: var(--font-montserrat-bold);
            color: #606972;
            margin-top: 0.38rem;
            margin-bottom: 0.36rem;
            text-align: left;
            padding-left: 0.5rem;
        }
        .logined-btn-container {
            padding-left: 0.5rem;
            border-bottom: 0.02rem solid #efefef;
            .common-nav-btn {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                height: 1rem;
                @include use-font-title(#606972);
                .content-icon {
                    align-items: center;
                }
            }
            .common-nav-btn + .common-nav-btn {
                border-top: 0.02rem solid #efefef;
            }
        }
    }

    // logout
    .sign-out-btn .logout.logout-btn {
        margin: 1.2rem 0 0.4rem;
        font-size: 0.3rem;
        font-family: var(--font-montserrat-medium);
    }
}
