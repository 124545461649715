@import "@/src/styles/common.scss";
.wish-item-box {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0.2rem;
  .wish-item-close-btn {
    text-align: right;
    .delete-icon {
      font-size: 0.44rem;
    }
  }
  .wish-item-img {
    display: block;
    width: 220px;
    min-height: 110px;
    margin: 0 auto;
  }
  .desc {
    display: flex;
    justify-content: center;
    align-items: center;

    .fill {
      flex: 1;
      text-align: right;
    }

    .wish-item-name {
      font-size: 0.26rem;
      color: #2d2d2d;
      margin: 0;
    }

    .wish-item-price {
      flex: 1 1 0%;
      text-align: left;
    }
    .slod_out {
      text-align: center;
    }
  }
}
