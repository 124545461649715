@import "@/src/styles/common.scss";
.page-item {
    padding: 0.4rem 0.2rem;
    background: #fff;
    margin-top: 0.1rem;
    display: grid;
    grid-template-columns: repeat(2, 2fr);
    grid-row-gap: 0.2rem;
    .flex-start {
        justify-content: flex-start;
        line-height: 0.52rem;
        grid-column: 1/3;
        &:last-of-type {
            grid-column: 1/2;
        }
        .lcon-img {
            width: 0.4rem;
            margin-right: 0.18rem;
            margin-left: 0.04rem;
        }
    }
    .exchange-icon-container {
        justify-content: flex-start;
        margin: 0.2rem 0;
        .icon-exchange {
            color: #000;
            font-size: 0.4rem;
            margin-right: 0.18rem;
        }
    }
    .hidden-util-lconlast {
    }
    // &:last-of-type {
    //     .hidden-util-lconlast {
    //         display: block;
    //     }
    // }
    .advantage-title {
        @include use-font-normal();
    }
    .view-more-btn {
        font-size: 0.24rem;
        color: $main;
        margin-left: 0.2rem;
    }
}

.modal-header-container {
}
.modal-container {
    padding: 0;
    .advantage-modal-title {
        @include use-font-title();
        margin-top: 0.4rem;
        margin-bottom: 0.2rem;
        text-align: left;
    }
    .advantage-modal-title:first-of-type {
        margin-top: 0;
    }
    .advantage-modal-text {
        @include use-font-normal();
        text-align: left;
    }
}
