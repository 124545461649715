@import "@/src/styles/common.scss";
.bottom-box {
    flex: none;
    .img-line {
        display: flex;
        flex: 1;
        padding: 0.2rem 0 0;
        overflow: auto;
        .loading-dom {
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .upload {
            display: flex;
            justify-content: center;
            align-items: center;
            background: #f5f5f5;
            font-size: 0.28rem;
            font-family: var(--font-montserrat-medium);
            color: $main;
            line-height: 0.32rem;
            text-align: center;
        }
        .img-item-box {
            .circle-bg {
                position: absolute;
                top: -10px;
                right: -10px;
                width: 20px;
                height: 20px;
                display: flex;
                align-items: center;
                justify-content: center;
                background: #000;
                opacity: 0.5;
                border-radius: 50%;
                cursor: pointer;
                color: #fff;
                transform: translateY(10px);
                font-size: 0.24rem;
            }
        }
    }
}

// pd-modal-children-container
.pd-modal-children-container {
    padding-bottom: 0.66rem !important;
    .pd-content {
        .pd-desc {
        }
        .pd-image-container {
            margin: 0.08rem auto 0;
            text-align: center;
            .pd-example {
                width: 4.4rem;
                margin: 0 auto;
            }
            .pd-title {
                padding: 0.26rem 0 0.18rem;
                font-size: 0.24rem;
                line-height: 0.32rem;
                color: #000;
                margin: 0;
            }
            .pd-select {
                width: 5rem;
                height: 0.72rem;
                background: #fff;
                border: 0.02rem solid #000;
                margin-bottom: 0.4rem;
                outline: none;
            }
            .pd-submit.btn {
                width: 5rem;
            }
        }
    }
}
.pd-options-paper {
    .select-options {
        font-size: 0.28rem;
    }
}
.img-try-on {
    .manual-box {
        .people {
            position: relative;
            max-width: initial;
        }
    }
    .people {
        position: relative;
        max-width: initial;
        cursor: move;
    }
    .img-box {
        .notice-box {
            .set-notice-show {
                @include use-icon-normal(null, #fff);
            }
        }
        .loading {
            position: absolute;
            top: calc(50% - 30px);
            left: calc(50% - 30px);
            transform: scale(0.8);
        }
    }
    .user-confirm-img {
        margin-top: 0.32rem;
        .bottom-line-btns {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }
    .pd-desc {
        padding: 1.04rem 0.5rem 0.08rem;
        font-size: 0.24rem;
        line-height: 0.32rem;
        color: #000;
    }
    .user-confirm {
        .detected {
            padding: 0.3rem 0.3rem 0;
            @include use-font-normal();
        }
        .btns {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0.4rem 0.4rem 0;
            .re-upload {
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
                width: auto;
                height: 0.6rem;
                margin: 0 0.36rem 0 0;
                background: #fff;
                border: 0.02rem solid $main;
                @include use-font-normal($main);
                font-family: var(--font-montserrat-medium);
                border-radius: 0.08rem;
                line-height: 1.5;
                padding: 0.18rem 0.44rem;
                .reupload-input {
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    opacity: 0;
                    z-index: 10;
                    cursor: pointer;
                }
            }
        }
    }
}

.try-on-products-title {
    background: rgba(0, 0, 0, 0.5);
    border-radius: 0.4rem;
    @include use-font-title(#fff);
    height: auto;
    left: 0.2rem;
    padding: 0 0.2rem;
    position: absolute;
    top: 0.1rem;
}
.ani-glasses {
    animation: glasses-animation 6s linear infinite;
    animation-delay: 0.5s;
}
@keyframes glasses-animation {
    0% {
        opacity: 1;
    }
    20% {
        opacity: 1;
    }
    40% {
        opacity: 0;
    }
    60% {
        opacity: 0;
    }
    80% {
        opacity: 1;
    }
    100% {
        opacity: 1;
    }
}
.img-try-on {
    display: flex;
    flex-direction: column;
}

.img-try-on .img-box {
    overflow: hidden;
    position: relative;
    text-align: center;
    flex: none;
}
@media (min-width: 768px) {
    .img-try-on.img-box {
        text-align: center;
    }
}
.img-try-on .edit-pd {
    position: absolute;
    bottom: 0.54rem;
    right: 0.44rem;
    height: 0.52rem;
    padding: 0 0.38rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #000;
    color: #fff;
    opacity: 0.6;
    border-radius: 4px;
    font-size: 0.28rem;
    line-height: 0.32rem;
}
.img-try-on .notice-box {
    position: absolute;
    display: flex;
    padding: 0.16rem 0.2rem;
    bottom: 0;
    color: #fff;
    background: rgba(0, 0, 0, 0.5);
}
.img-try-on .notice-box div {
    @include use-font-small(#fff);
    text-align: left;
}
.img-try-on .img-line > div {
    position: relative;
    flex: none;
    width: 1.48rem;
    height: 1.58rem;
    margin-left: 0.2rem;
}
.img-try-on .img-line img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    cursor: pointer;
}
.upload-notice {
    @include use-font-normal();
    padding: 0.6rem 0.4rem 0.4rem;
}
.first-use-img {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 0 0.4rem;
    height: 2.7rem;
}

.first-use-img img {
    width: 2.32rem;
}
.first-use-img img:last-child {
    margin-left: 0.7rem;
}
.network-error-container {
    text-align: center;
    .network {
        padding-bottom: 0.4rem;
    }
}
.try-on-drawer {
    .modal-content-box {
        .notice-text {
            padding: 0 0.58rem 0.42rem;
            color: $text;
        }
        .button-container {
            display: flex;
            align-items: center;
            flex-direction: column;
            .try-on-upload-before {
                &.button-select-picture {
                    width: auto;
                }
                &.button-back {
                    height: 0.6rem;
                    padding: 0 0.44rem;
                }
            }
            .try-on-upload-before + .try-on-upload-before {
                margin-top: 0.4rem;
            }
        }
    }
}

.modal-content-box .blue-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 5rem;
    height: 0.72rem;
    background: $main;
    border-radius: 0.08rem;
    font-size: 0.28rem;
    font-family: var(--font-montserrat);
    color: #ffffff;
    line-height: 0.32rem;
    margin: 0 auto 0.42rem;
}
.modal-content-box .back {
    font-size: 0.24rem;
    font-family: var(--font-montserrat);
    color: $main;
    line-height: 0.32rem;
    margin-bottom: 0.44rem;
}
.img-try-on .img-line-box {
    display: flex;
    align-items: center;
}
.img-try-on .img-line-box span {
    flex: 0;
}
.img-try-on svg {
    position: absolute;
    width: 0.52rem;
    height: 0.52rem;
}
.img-try-on .glasses {
    transition: 0.3s all;
}
.img-try-on svg.hide {
    display: none;
}
.img-try-on .gtm-img-try-on-del {
    right: -6px;
    top: -10px;
    width: 20px;
    height: 20px;
    transform: translateY(0.2rem);
}
.img-try-on .manual-box {
    width: 100%;
    height: 100%;
    background: #f2f2f2;
}
.try-on-select {
    border-bottom: 0.02rem solid #eef0f0;
    justify-content: flex-start !important;
}
.try-on-select .tab-under-line {
    width: 0.98rem !important;
    bottom: -0.02rem !important;
}
.try-no-notice {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100vw;
    text-align: center;
}
.try-no-notice .am-modal-content {
    border-radius: 0;
    padding-top: 0;
}
.try-no-notice .am-modal-content .am-modal-body {
    padding: 0 0;
}
.pc-pd {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 10px;
    border-bottom: 0.02rem solid #eef0f0;
}
.pc-pd span {
    margin-right: 0.28rem;
}
.pc-pd select {
    width: 2.6rem;
    height: 0.48rem;
    background: #fff;
    border: 0.02rem solid $text;
}
.img-try-on-pd-tooltip-wrapper .ant-tooltip-inner {
    background: #fff;
    width: 6.2rem;
    padding: 0 0;
}
.am-modal-wrap {
    background-color: rgba(0, 0, 0, 0.5);
}
.am-modal-mask {
    background-color: rgba(0, 0, 0, 0);
}
.bottom-line-btns {
    width: 100%;
    position: fixed;
    bottom: 0;
    padding: 0.14rem 0.4rem;
    background: #fff;
    box-shadow: 0px -0.06rem 0.12rem rgba(0, 0, 0, 0.08);
}
