@import "@/src/styles/common.scss";
.page-item {
    // padding: 0.4rem 0.2rem;
    background: #fff;
    margin-top: 0.1rem;
    .similar-frames {
        @include use-font-title();
        background: #fff;
    }
    .similar-frames-container {
        background: #fff;
        margin-top: 0.1rem;
        padding: 0.4rem 0.2rem 0.6rem;
        margin-bottom: 0.1rem;

        .like-product-list {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            padding-top: 0.4rem;
            .like-product-item {
                width: calc(50% - 0.08rem);
                padding: 0.3rem 0.2rem 0.3rem;
                box-shadow: 0px 6px 12px 2px rgba(0, 0, 0, 0.05);
                &:nth-of-type(n + 3) {
                    margin-top: 0.34rem;
                }
                .item-icon {
                    justify-content: space-between;
                    padding: 0;
                    margin-top: 0.2rem;
                    .products_name {
                        font-size: 0.24rem;
                    }
                    .icon {
                        @include use-icon-small(null, $main);
                        margin-left: 0.1rem;
                    }
                }
                .price {
                    font-size: 0.24rem;
                }
                &:nth-child(2n) {
                    border-right: none;
                }
            }
        }
    }
}

.grid-item {
    background-color: #fff; /* 单元格背景色 */
    position: relative; /* 相对定位，用于添加边框 */
}
.grid-item::after {
    content: ""; /* 创建一个伪元素 */
    position: absolute; /* 绝对定位 */
    top: 0; /* 从顶部开始 */
    left: 0; /* 从左侧开始 */
    width: 100%; /* 宽度为100% */
    height: 100%; /* 高度为100% */
    pointer-events: none; /* 防止伪元素影响点击事件 */
}
