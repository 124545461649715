@import "@/src/styles/common.scss";
.WriteQuestionsForm {
    & > div {
        display: none;
        &:first-child,
        &:nth-child(2) {
            display: block;
        }
    }
    &.hover > div {
        display: block !important;
    }
    .questionsList {
        margin: 0.2rem 0;
        line-height: 0.4rem;
        .question-item {
            font-size: 0.26rem;
            color: $text;
            font-family: var(--font-montserrat);
        }
        .answer-radio {
            display: flex;
            flex-direction: row;
            padding: 0.1rem 0;
            .checkbox-or-radio-item {
                margin-right: 0.5rem;
            }
        }
        .questionsListInputText {
            padding: 0.05rem 0;
            margin: 0.05rem 0;
        }
    }
    // button
    .show-more {
        margin-top: 0.2rem;
        display: block !important;
        width: auto !important;
        height: auto !important;
    }
}
