@import "@/src/styles/common.scss";
.product-accordion-container {
    margin: 0.1rem 0;
    .frame-details-show {
        margin-bottom: 0.1rem;
        background: #fff;
        .frame-details-title {
            @include use-font-title();
            padding-left: 0.2rem;
            padding-top: 0.4rem;
        }
    }
    .page-descriptions-content {
        margin-bottom: 0.1rem;
        .product-title {
            font-size: 0.28rem;
            font-family: var(--font-montserrat-medium);
            margin-bottom: 0.2rem;
        }
    }

    .summary-content {
        font-family: var(--font-montserrat-medium);
        font-size: inherit;
        margin: 0 !important;
        .expanded {
            margin: 0 !important;
        }
    }
    .expanded {
        &.expanded-icon-wrapper {
            transform: rotate(90deg) !important;
        }
    }
}
