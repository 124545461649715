@import "@/src/styles/common.scss";
.title-line {
    .title-content {
        justify-content: flex-start;
        .pro-modal-title {
            font-size: 0.28rem;
            position: relative;
            font-family: var(--font-montserrat-medium);
            margin: 0;
        }
        .prodct-icon {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            font-size: 0.22rem;
            width: auto;
            height: 0.32rem;
            padding: 0 0.2rem;
            border-radius: 0.4rem;
            margin-left: 0.14rem;
            color: #fff;
            .hot {
                background: #e6a958;
            }
            .new {
                background: #58b9e6;
            }
        }
        .kids {
            span:nth-child(1) {
                color: #58b9e6;
            }
            span:nth-child(2) {
                color: #e6a958;
            }
            span:nth-child(3) {
                color: #e787a2;
            }
            span:nth-child(4) {
                color: #e787a2;
            }
        }
        .no-rx-icon {
            font-size: 0.24rem;
            margin-left: 0.1rem;
            color: $text_3;
        }
    }
}
