@import "@/src/styles/common.scss";
.color-select-container {
    width: 100%;
    background: #fff;
    padding: 0;
    text-align: left;
    color: $text;
    .color-main-img {
        width: 2rem;
        min-height: 1rem;
        margin-bottom: 0.4rem;
    }
    .product-detail-title {
        font-size: 0.28rem;
        white-space: nowrap;
        span {
            font-size: 0.28rem;
            margin-bottom: 0.2rem;
            font-family: var(--font-montserrat-medium);
        }
    }
    .color-select {
        margin-bottom: 0.4rem;
    }
}
