@import "@/src/styles/common.scss";
.color-select-container {
    margin-top: 0.1rem;
    display: flex;
    align-items: center;
    position: relative;
    .product-select {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        height: min-content;
        padding: 0;
        list-style: none;
    }
    .hidden-glasses-wrapper {
        margin-left: 0;
        position: relative;
        font-size: 0;
        .arrow-down {
            @include use-icon-normal(null, rgb(0, 0, 0, 0.16));
            font-size: calc(0.48rem * var(--scale));
            transition: transform 0.3s ease 0s;
        }

        .hidden-glasses-container {
            background: #fff;
            border-radius: 0.1rem;
            box-shadow: 0 0.05rem 0.1rem 0 rgba(83, 83, 83, 0.28);
            display: flex;
            height: auto;
            left: calc(var(--left) - 0.18rem);
            margin: auto;
            opacity: 1;
            padding: 0.05rem 0.18rem;
            position: absolute;
            top: 0;
            transform: translateY(100%);
            transform-origin: 0 0;
            transition: all 0.3s ease-in-out 0s;
            width: 3.4rem;
            z-index: 1;
            li {
                list-style: none;
            }
        }
    }
}
.row-center {
    align-items: center;
    justify-content: center;
}
.product-select-item-content {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0.03rem 0.03rem;
    padding: 0.08rem;
    width: 0.453rem;
    height: 0.453rem;
    border-radius: 50%;
    border: 0.02rem solid #fff;
    box-sizing: border-box;
}

.product-select-item {
    display: block;
    flex-shrink: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-size: cover;
    cursor: pointer;
    background-size: 100% 100%;
    width: 0.3rem;
    height: 0.3rem;
}

.color-more {
    display: inline-block;
    margin: 0 0.03rem;
    padding: 0 0.08rem;
    border-radius: 50%;
    .color-more-icon {
        font-size: 0.48rem;
        line-height: 1;
        color: #bfbaba;
    }
}

//.color-more img {
//  display: inline-block;
//  width: 0.43rem;
//  height: 0.43rem;
//  transition: 0.3s all;
//}

.home-color-more-panel {
    min-width: 3rem;
    background: #fff;
    box-shadow: 0 0.05rem 0.09rem 0 rgba(83, 83, 83, 0.28);
    border-radius: 0.1rem;
    display: flex;
    position: absolute;
    right: calc(50% - 1.58rem);
    margin: auto;
    top: 0.7rem;
    padding: 0.05rem 0.18rem;
    z-index: 1;
    transition: all 0.3s ease-in-out;
    transform-origin: 0 0;
}

.Black {
    background: #000 none repeat scroll 0 0;
    color: #fff;
}

.White {
    background: #fff none repeat scroll 0 0;
    border: 0.02rem solid #ccc;
    box-sizing: border-box;
}

.Clear {
    background: #dcdcdc none repeat scroll 0 0;
}

.Antique {
    background: #4a3c29 none repeat scroll 0 0;
}

.Blue {
    background: #00f none repeat scroll 0 0;
}

.Brown {
    background: #a52a2a none repeat scroll 0 0;
}

.Burgundy {
    background: #5c0323 none repeat scroll 0 0;
}

.Gold {
    background: #9a7a3f none repeat scroll 0 0;
}

.Grain {
    background: #f5deb3 none repeat scroll 0 0;
}

.Green {
    background: #008000 none repeat scroll 0 0;
}

.Grey {
    background: #808080 none repeat scroll 0 0;
}

.Gunmetal {
    background: #655742 none repeat scroll 0 0;
}

.Matt {
    background: #313131 none repeat scroll 0 0;
}

.Multicolor {
    background: rgba(0, 0, 0, 0)
        url("/__firmoo/static/images/icon/Multicolored.jpg?inline") no-repeat
        scroll;
    background-size: 100% 100%;
}

.Orange {
    background: #ffa500 none repeat scroll 0 0;
}

.Pattern {
    background: rgba(0, 0, 0, 0)
        url("/__firmoo/static/images/icon/Pattern.jpg?inline") no-repeat scroll;
    background-size: 100% 100%;
}

.Pink {
    background: #ffc0cb none repeat scroll 0 0;
}

.Purple {
    background: #800080 none repeat scroll 0 0;
}

.Red {
    background: #f00 none repeat scroll 0 0;
}

.Silver {
    background: #c0c0c0 none repeat scroll 0 0;
}

.Tortoise {
    background: rgba(0, 0, 0, 0)
        url("/__firmoo/static/images/icon/Tortoise.png?inline") no-repeat scroll;
    background-size: 100% 100%;
}

.Yellow {
    background: #ff0 none repeat scroll 0 0;
}

.Chocolate {
    background: #d2691e none repeat scroll 0 0;
}

.Ivory {
    background: #f6f6cb none repeat scroll 0 0;
}
