@import "@/src/styles/common.scss";
.try-on-container {
    .try-on-close-icon {
        top: 6px;
        right: 10px;
        width: 30px;
        height: 30px;
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;
        background: rgb(0, 0, 0);
        opacity: 0.5;
        border-radius: 50%;
        cursor: pointer;
        color: #fff;
        padding: 0;
        .icon-font-size {
            font-size: 0.44rem;
        }
    }
}
