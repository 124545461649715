@import "@/src/styles/common.scss";
.firmoo-forget-password {
    text-align: left;
    // padding: 0 0.2rem;
    p {
        text-align: justify;

        // &.text-forgot {
        //     // padding-top: 0.4rem;
        //     font-size: 0.28rem;
        //     line-height: 1.2;
        //     font-family: var(--font-montserrat);
        // }
    }
    .forgot-input {
        margin-top: 0.4rem;
    }
    .send-email-to-me {
        border-radius: 0.08rem;
        margin: 0.4rem auto 0;
    }
    .send-email-to-me ~ .return-to-login {
        font-size: 0.32rem;
        margin-top: 0.4rem;
    }
    .firmoo-register-error {
        padding: 0.1rem 0.2rem;
        background: $warning;
        margin-top: 0;
        width: fit-content;
        @include use-font-small(#fff);
    }
}
