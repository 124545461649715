@import "@/src/styles/common.scss";
.facebook-btn {
    height: 0.71rem;
    line-height: 0.71rem;
    background-color: #4a67a2;
    color: white;
    transition: all 0.2s;
    box-sizing: border-box;
    font-size: 0.26rem;
    text-align: center;
    &:hover {
        border: 0.02rem solid #4a67a2;
        color: #4a67a2;
        background-color: white;
    }
}
