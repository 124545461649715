@import "@/src/styles/common.scss";
.footer-container {
    width: 100%;
}

.m-nav-back-to-old {
    margin: 0;
    text-decoration: underline;
    font-size: 0.3rem;
    color: $text;
}
