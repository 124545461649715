@import "@/src/styles/common.scss";
.loading-animation-wrapper {
    width: 100%;
    text-align: center;
    margin-top: 25%;

    .loading-animation-container {
        width: 80px;
        height: 40px;
        display: inline-block;
        .loading-animation-content {
            isolation: isolate;
            width: 100%;
            height: 100%;
            position: relative;
            transform: translateZ(0) scale(0.6);
            backface-visibility: hidden;
            div {
                position: absolute;
                width: 40px;
                height: 40px;
                border-radius: 50%;
                mix-blend-mode: multiply;
                box-sizing: content-box;
                &:nth-child(1) {
                    background: #2ea7e0;
                    animation: animation-loading-content 1.1363636363636365s
                        linear infinite;
                    animation-delay: -0.5681818181818182s;
                }
                &:nth-child(2) {
                    background: #e198ad;
                    animation: animation-loading-content 1.1363636363636365s
                        linear infinite;
                    animation-delay: 0s;
                }
                &:nth-child(3) {
                    background: #2ea7e0;
                    animation: animation-loading-content-third-div
                        1.1363636363636365s linear infinite;
                    animation-delay: -0.5681818181818182s;
                }
            }
        }
    }
    .loading-text {
        color: #8e9399;
        font-size: 0.24rem;
        font-family: var(--font-montserrat);
        line-height: 1.2;
    }
}
// 动画
@keyframes animation-loading-content-third-div {
    0% {
        opacity: 1;
        transform: translate(0 0);
    }
    49.99% {
        opacity: 1;
        transform: translate(40px, 0);
    }
    50% {
        opacity: 0;
        transform: translate(40px, 0);
    }
    100% {
        opacity: 0;
        transform: translate(0, 0);
    }
}
@keyframes animation-loading-content {
    0% {
        transform: translate(0, 0);
    }
    50% {
        transform: translate(40px, 0);
    }
    100% {
        transform: translate(0, 0);
    }
}
