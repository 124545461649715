@import "@/src/styles/common.scss";
.badge-container {
    .badge-class {
        background-color: var(--color);
        border-radius: 50%;
        color: #fff;
        font-size: 0.24rem;
        height: auto;
        padding: 0;
        top: 0;
        transform: scale(0.8) translate(50%, -5%);
        line-height: 1;
        text-align: center;
        padding: 0.08rem;
        display: block;
        text-align: center;
        font-family: var(--font-montserrat);
        min-width: 0.4rem;
    }
}

.icon-font-to-48 {
    font-size: 0.48rem;
}
