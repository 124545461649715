@import "@/src/styles/common.scss";
.basket-share {
    .basket-share-container {
        align-items: center;
    }
}

.header-class-line {
    border-bottom: none !important;
}
.share-cart-container {
    padding-top: 0 !important;
    .width-auto {
        width: 100%;
    }
}
.product-basket-item-wrapper {
    .pc-basket-item-img-container {
        text-align: center;
        position: relative;
        // share
        .share-link {
            min-height: 3rem;
            width: 5.43rem;
            margin: 0 auto;
            display: block;
            .basket-img {
                width: 100%;
                height: auto;
                &.absolute-img {
                    position: absolute;
                    top: 0;
                    left: 0;
                }
                &.ani-glasses {
                    animation: glasses-animation 6s linear infinite;
                    animation-delay: 0.5s;
                }
            }
        }
        // not-share
        .edit-remove-container.no-share-tag {
            position: absolute;
            right: 0.2rem;
            top: -0.48rem; // 抵消掉checkbox的高度
            .basket-remove-btn {
                margin-left: 0.1rem;
                padding-left: 0.2rem;
                @include use-font-normal();
                color: $main;
                cursor: pointer;
            }
            .basket-edit-btn {
                @include use-font-normal();
                color: $main;
                cursor: pointer;
                padding: 0 0.2rem;
                border-right: 0.02rem solid $gray_4;
            }
        }
    }
    .pc-basket-item-detail {
        .basket-attr-line {
            @include use-font-normal();
            .sub-text {
                color: $text_3;
            }
        }
        .basket-low-stock {
            color: #f69000;
            margin-top: 0.2rem;
            &.basket-only {
                @include use-font-small(#f69000);
                font-size: 12px;
            }
        }

        .basket-total-price {
            @include use-font-title();
            text-transform: capitalize;
        }
        .lens-details-wrapper {
            .lens-details-container {
                justify-content: space-between;
                @include use-font-normal();
                .lens-detail-arrow-content {
                    display: flex;
                    align-items: center;
                    .lens-detail-icon {
                        padding-left: 0.2rem;
                        cursor: pointer;
                        width: auto;
                        height: auto;
                        @include use-icon-small();
                    }
                }
            }
            .lens-detail-content {
                transition: all 0.5s;
                overflow: hidden;
                &.lens-detail-content-show {
                    max-height: 500px;
                    opacity: 1;
                }
                &.lens-detail-content-hide {
                    max-height: 0;
                    opacity: 0;
                }
                .pc-lens-package-container {
                    display: flex;
                    align-items: flex-end;
                    justify-content: space-between;
                    border-top: 0.02rem solid #d8d8d8;
                    padding-top: 0.2rem;
                    @include use-font-normal();
                    p {
                        @include use-font-small();
                        margin-bottom: 0.1rem;
                    }
                    .red {
                        color: red;
                        margin-right: 0.06rem;
                    }
                    .price {
                        flex-shrink: 0;
                    }
                }
            }
        }
        // 配件
        .basket-clips-content {
            margin-top: 0.4rem;
            .clip-img-content {
                justify-content: flex-start;
                align-items: flex-start;
                .clip-stock-description {
                    @include use-font-small();
                    font-size: 12px;
                    text-align: center;
                    margin-top: 0.1rem;
                }
            }
        }
    }
}
.footer-container {
    text-align: center;
}

// basket-title
.basket-title-container {
    background-color: #f9eaef;
    padding: 0.24rem 0.18rem;
    border-radius: 0.08rem;
    text-align: left;
    margin-bottom: 0.4rem;
    @include use-font-small(#e198ad);
    font-size: 0.2rem;
    &.pc-basket-title-container b {
        font-family: var(--font-montserrat-medium);
    }
}

@keyframes glasses-animation {
    0% {
        opacity: 1;
    }
    20% {
        opacity: 1;
    }
    40% {
        opacity: var(--img-opacity);
    }
    60% {
        opacity: var(--img-opacity);
    }
    80% {
        opacity: 1;
    }
    100% {
        opacity: 1;
    }
}

// 蓝光升级
.basket-upgrade-descriptions,
.basket-add-clips {
    justify-content: flex-start;
    align-items: flex-start;
    @include use-font-small();
    margin-top: 0.6rem;
}
