@import "@/src/styles/common.scss";
.product-detail-container {
    .products-description-extra {
        @include use-font-normal();
        margin-bottom: 0.2rem;
        .descrip-hide {
            text-overflow: -o-ellipsis-lastline;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            line-clamp: 2;
            -webkit-box-orient: vertical;
        }
        .togger-btn {
            color: $main;
        }
    }
    .show-customers {
        background: #f9eaef;
        padding: 0.1rem;
        margin-bottom: 0.2rem;
        @include use-font-small(#e15e8a);
    }
    .detail-box {
        background-color: #fff;
        padding-bottom: 0.6rem;
        .render-details-container {
            justify-content: space-between;
            .render-details-content {
                justify-content: flex-start;
                margin: 0;
            }
        }
        .key-list-container {
            justify-content: flex-start;
            padding: 0.2rem 0 0;
        }
        .icon-circle {
            width: 0.08rem;
            height: 0.08rem;
            margin-right: 0.1rem;
            background: rgba(105, 105, 105, 1);
            border-radius: 50%;
        }
        .desc {
            @include use-font-small();
            display: flex;
            align-items: center;
            margin-right: 0.2rem;
            .product-details-span {
                color: $text;
                font-size: inherit;
            }
            span {
                font-size: inherit;
            }
        }
    }
}

.rx-ranges {
    justify-content: space-between;
}

.products-info-icon {
    font-size: 0.3rem;
    color: $text;
    margin-right: 0.1rem;
}

.package-detail-contaner {
    .rxrange-title {
        font-size: 0.36rem;
        text-align: left;
        font-family: var(--font-montserrat-medium);
        color: $text;
        &.margin-top {
            margin-top: 0.4rem;
        }
    }
    .range-content {
        font-size: 0.28rem;
        text-align: left;
    }
}
